<template transition="scale-transition">
  <v-container>

    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="pl-3">
        <v-data-table
          ref="StatusOffersTable"
          :headers="headers"
          :items="items"
          id="virtual-scroll"
          v-scroll.self="onScroll"
          v-bind="$attrs"
          :loading="loader"
          :loading-text="$t('components.app.datatable.loading_data')"
          disable-pagination
          hide-default-footer
          disable-sort
          :height="$vuetify.breakpoint.xl ? 600 : 410"
          fixed-header
          v-on="$listeners"
        >
          <template
             v-for="(header, index) in translatedHeaders"
            v-slot:[`header.${header.value}`]
            #activator="{ on, attrs }"
          >

            <div class="header-item">
              <div class="header-text">
                <span class="header-title">{{ header.text }}</span>
              </div>
              <div class="header-btn">
                <template>
                  <v-btn
                    icon
                    v-show="header.urlFilter !== ''"
                    text
                    @click="loadFilter(header.name)"
                    :disabled="filterFlag"
                    color="primary"
                    :color="
                     !selectedFilterOffersStatus[header.name]?.length ? 'primary' : 'tercery'
                    "
                  >
                    <v-icon size="small"> fa fa-filter </v-icon>
                  </v-btn>
                  <SelectFilterComponent
                    multiple
                    :url="header.urlFilter"
                    v-model="header.vmodel"
                    :label="$t('components.app.datatable.filters')"
                    class="menuFilter"
                    v-show="header.showFilter"
                    @change="selectedFilterOnClick(header.vmodel, header.name)"
                    outlined
                    :clearable="false"
                    :menu-props="{ maxWidth: 180 }"
                    ref="SelectFilterComponent"
                  >
                  <template v-slot:selection="{ item }">
                    <v-chip v-if="header.showFilter === 'false'" small>
                      <span ></span>
                    </v-chip>
                  </template>

                  </SelectFilterComponent>
                </template>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{ item }">
            <v-icon
              :class="
                item.state == 'Open'
                  ? 'blue--text fa-solid fa-circle mr-2'
                  : item.state == 'Cancelled'
                  ? 'red--text fa-solid fa-circle mr-2'
                  : item.state == 'Closed'
                  ? 'red--text fa-solid fa-circle mr-2'
                  : 'green--text fa-solid fa-circle mr-2'
              "
            />
            {{
              item.state == "Open"
                ? $t("views.reports.status_offers.table.open")
                : item.state == "Cancelled"
                ? $t("views.reports.status_offers.table.cancelled")
                : item.state == "Closed"
                ? $t("views.reports.status_offers.table.closed")
                : $t("views.reports.status_offers.table.confirmed")
            }}
          </template>
          <template #[`item.createdOn`]="{ item }">
            {{ moment.utc(item.createdOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm") }}
          </template>
          <template #[`item.updateOn`]="{ item }">
            {{
              !item.updateOn
                ? $t("views.reports.status_offers.table.no_confirmed")
                : moment.utc(item.updateOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm")
            }}
          </template>


          <template #no-data>
            <p>{{ $t("components.app.datatable.no_results_not_found") }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="information-page">
      {{ information }}
    </div>
  </v-container>
</template>

<script>
import validate from "@/plugins/validate";
import { mapActions } from "vuex";
import moment from 'moment';

export default {
  name: "TableComponent",
  props: {
    searchP: {
      type: String,
      default: "",
    },
    columnsOn: {
      type: Boolean,
      default: false,
    },
    urlFilter: {
      type: String,
      default: "",
    },
    noPagine: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ...validate,
    localItems: null,
    loader: false,
    newItems: [],
    timeout: null,
    length: 0,
    totalItems: 0,
    perPage: 20,
    rowsConsult: 0,
    totalPages: 0,
    pageUpdate: 0,
    search: "",
    selectedFilterOffersStatus: {
      tripNumber: [],
      originCity: [],
      originAddress: [],
      destinyCity: [],
      destinyAddress: [],
      transporter: [],
      state: [],
      createdOn: [],
      updateOn: [],
    },
    filterFlag: false,
    headers: [
      {
         text: "N° de Viaje",
        class: "text-center",
        align: "center clic",
        value: "tripNumber",
        urlFilter: "Offers/GetTripNumbersRequestOffers",
        vmodel: "tripNumber",
        name: "tripNumber",
        showFilter: false,
      },
      {
        text: "Ciudad de Origen",
        class: "text-center",
        align: "center clic",
        value: "originCity",
        urlFilter: "Offers/GetOriginCityOffers",
        vmodel: "originCity",
        name: "originCity",
        showFilter: false,
      },
      {
        text: "Dirección de Origen",
        class: "text-center",
        align: "center clic",
        value: "originAddress",
        urlFilter: "Offers/GetOrginAddresRequestOffers",
        vmodel: "originAddress",
        name: "originAddress",
        showFilter: false,
      },
      {
        text: "Ciudad de Destino",
        class: "text-center",
        align: "center clic",
        value: "destinyCity",
        urlFilter: "Offers/GetDestinyCityRequestOffers",
        vmodel: "destinyCity",
        name: "destinyCity",
        showFilter: false,
      },
      {
        text: "Dirección de Destino",
        class: "text-center",
        align: "center clic",
        value: "destinyAddress",
        urlFilter: "Offers/GetDestinyAddressRequestOffers",
        vmodel: "destinyAddress",
        name: "destinyAddress",
        showFilter: false,
      },
      {
        text: "Transportista",
        class: "text-center",
        align: "center clic",
        value: "transporter",
        urlFilter: "Offers/GetTransporterNameRequestOffers",
        vmodel: "transporter",
        name: "transporter",
        showFilter: false,
      },
      {
        text: "Estado",
        class: "text-center",
        value: "state",
        width: "10%",
        align: "clic",
        urlFilter: "Offers/GetStatusRequestOffers",
        vmodel: "state",
        name: "state",
        showFilter: false,
      },
      {
        text: "Fecha y Hora de Creación",
        class: "text-center",
        align: "center clic",
        value: "createdOn",
        urlFilter: "Offers/GetCreateOnRequestOffers",
        vmodel: "createdOn",
        name: "createdOn",
        showFilter: false,
      },
      {
        text: "Fecha y Hora de Confirmación",
        class: "text-center",
        align: "center clic",
        value: "updateOn",
        urlFilter: "",
        vmodel: "updateOn",
        name: "updateOn",
        showFilter: false,
      },
    ],
  }),
  components: {
    SelectFilterComponent: () =>
      import(
        /* webpackChunkName: "SelectFilterComponent" */ "./SelectFilter/SelectFilterComponent.vue"
      ),
  },
  computed: {
    items() {
      return this.newItems;
    },
    information() {
      const start = this.length === "Healthy" ? 0 : 1;
      const total = this.length === "Healthy" ? 0 : this.length;
      if (!this.noPagine) {
        return this.length
          ? `${this.$t(
              "components.app.datatable.items_displayed"
            )} ${start} - ${this.totalItems} ${this.$t(
              "components.app.datatable.of"
            )} ${total}`
          : "";
      } else {
        return this.length
          ? `${this.$t("components.app.datatable.items_displayed")} ${start} - ${
              this.totalItems
            } ${this.$t("components.app.datatable.of")} ${total}`
          : "";
        }
    },

    translatedHeaders() {
      return this.headers.map(header => ({
        ...header,
        text: this.$t(`views.reports.status_offers.table.${header.name}`),
      }));
    }

  },
  mounted() {
    this.loadData();
    this.getFilterOnClick();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    selectedFilterItems(name) {
      return this.selectedFilter[name];
    },
    onScroll(e) {
      if (!this.noPagine) {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.perPage);

          if (rows && this.pageUpdate <= this.totalPages) {
            this.pageUpdate++;
            this.loadData(this.pageUpdate);
            this.rowsConsult = rows;
          }
          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 500);
      }
    },
    async loadLimitCount() {

      let searchProp = this.searchP !== "" ? this.searchP : this.search;

      let search = searchProp == "" ? "" : `?search=${searchProp}`;

      await this.axios.get(`/Offers/RequestStateCount${search}`).then((res) => {
        this.length = res.data;
      });
    },
    loadDataNew() {
      this.pageUpdate = 0;
      this.loadData();
    },
    loadFilter(fieldName) {
      let indexToUpdate = this.headers.findIndex((item) => item.name == fieldName);
      if (!this.headers[indexToUpdate].showFilter) {
        this.$refs.SelectFilterComponent[indexToUpdate].loadData();
      }

      this.headers[indexToUpdate].showFilter = this.headers[indexToUpdate]
        .showFilter
        ? false
        : true;

      this.headers.forEach((element, index) => {
        if (element.name !== fieldName) {
          this.headers[index].showFilter = false;
        }
      });
    },
    async selectedFilterOnClick(selected, name) {
      this.filterFlag = true;
      this.selectedFilterOffersStatus[name] = selected;


      const createdOnSelected = this.selectedFilterOffersStatus.createdOn?.map((createdOnDate) => {
        const datecreatedOn = moment.utc(createdOnDate, "DD/MM/YYYY HH:mm:ss");
        const adjustedDatecreatedOn = datecreatedOn.add(5, 'hours');
        const formattedCreatedOnTime = adjustedDatecreatedOn.format("DD/MM/YYYY HH:mm:ss");
        return formattedCreatedOnTime;
      });

     this.selectedFilterOffersStatus.createdOn = createdOnSelected;


      await this.axios
        .post("/Filters/saveFiltersStateRequestOffers", this.selectedFilterOffersStatus)
        .then(() => {})
        .catch(function (error) {
          const e = error.response
            ? this.$t("components.app.datatable.error_message")
            : error.message.trim();
          this.showSnack({
            text: e,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loadDataNew();
          this.loader = false;
          this.filterFlag = false;
        });
    },
    async getFilterOnClick() {
      await this.axios
        .get("/Filters/GetFiltersStateRequestOffers")
        .then((response) => {
          if (response.data.objectFilter !== "null") {
            this.selectedFilterOffersStatus = JSON.parse(response.data.objectFilter);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loader = false;
        });
    },
    async loadData(scrollPage) {
      await this.loadLimitCount();
      let searchProp = this.searchP !== "" ? this.searchP : this.search;
      let search = searchProp == "" ? "" : `?search=${searchProp}`;
      const page = scrollPage ? scrollPage : 0;
      this.loader = true;
      try {
        let noPagine = !this.noPagine
          ? `${this.perPage}/${page}${search}`
          : "";
        const response = await this.axios.get(
          `/Offers/RequestStateList/${noPagine}`
        );

        if (!this.noPagine) {
          this.localItems = response.data;

          let totalPages = this.length / this.perPage;
          this.totalPages = parseInt(totalPages);

          this.newItems = scrollPage
            ? [...this.newItems, ...this.localItems]
            : [...this.localItems];
          this.totalItems = this.newItems.length;

        } else {
          this.newItems = response.data;
        }

      } catch (error) {
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
<style scoped>
#virtual-scroll {
  overflow-y: auto;
  width: 100%;
  display: inline-block;
  border-radius: 12px;
  border: 3px solid #5c48f7;
  overflow-x: auto;
  background: rgba(255, 255, 255, 0.6) !important;
}

#virtual-scroll ::v-deep thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
}

#virtual-scroll ::v-deep thead tr:last-child th {
  background-color: #fff !important;
  /* border-bottom: 2px solid green !important; */
 /* border-top: 2px solid green !important; */

  span {
    font-size: 13px !important;
  }
}

#virtual-scroll ::v-deep .v-data-table__wrapper {
  overflow-x: unset;
  overflow-y: unset;
  height: 103%;
}

/*
#virtual-scroll ::v-deep tbody tr:nth-child(2n + 1) {
  background-color: #eee;
} */

#virtual-scroll ::v-deep tbody tr .clic,
#virtual-scroll ::v-deep tbody tr .text-clic {
  cursor: pointer;
}

#virtual-scroll ::v-deep .v-data-table-header {
  top: 0;
}

.row-pointer ::v-deep tbody tr :hover {
  cursor: progress;
}

.border_primary {
/*  border: 3px solid #5c48f7 !important; */
  background: rgba(255, 255, 255, 0.6) !important;
  border-radius: 6px;
  /* max-width: 900px; */
}

.v-list-item {
  min-height: 38px !important;
}

.v-application ::v-deep .v-list-item__title {
  font-size: 0.8em;
}

.v-data-table-header ::v-deep th {
  font-size: 0.8em !important;
  text-align: center !important;
  font-weight: normal;
}

.information-page {
  text-align: right;
  margin-right: 1em;
}

.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.header-text {
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
}

.list-filters {
  padding: 0 0 0 0;
  height: 30em;
}

.list-title {
  margin: 0 20px 15px 15px;
  font-size: 16px;
}

.list-item-filter {
  background-color: #fff;
}

.filters-item {
  margin: 0;
  width: 150px;
  background-color: #fff;
}

.menuFilter {
  top: 40px;
  left: 140px;
  background-color: #fff;
  width: 180px;
  position: absolute;
  z-index: 1;
  .v-list-item__title {
    white-space: pre-wrap;
  }
}

.menuFilter ::v-deep .v-input__control > .v-input__slot {
  margin-bottom: 0;
}

.menuFilter ::v-deep .v-text-field__details {
  display: none !important;
}

.space-btn-Massive-offer {
  height: 50px;
}

.datetext-select {
  white-space: normal;
  display: flex;
}

span.header-title {
    font-weight: bolder;
    color: #0B0045;
  }


</style>
